@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

figure {
    overflow: hidden;
    position: relative;
    border-radius: 0.5rem;
}

figure::after {
    content: 'See More';
    font-family: "Menlo", "Courier", monospace;
    font-size: 2rem;
    letter-spacing: .2em;
    text-align: center;
    padding-top: 41%;
    color: #fff;
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(0,0,0,.4);
    transition: .8s;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    border-radius: 0.5rem;
}

a:hover figure::after {
    opacity: 1;
}

a:hover figure img {
    transform: scale(1.1);
}

a:hover {
    text-decoration: underline;
}

h5.meta-laby-name {
    font-family: "Menlo", "Courier", monospace;
}

.menlo {
    font-family: 'Caveat', cursive;
}

.tape {
    position: relative;
    width: 80%;
    color: #ffffff;/* 文字色 */
    background-color: #324256; /*枠背景色*/
}
.tape::before {
    display: block;
    position: absolute;
    content: "";
    width: 30%;
    height: 15%;
    left: 35%;
    top: -7%;
    background-color: rgba(180, 180, 180,0.7);/*テープ色*/
    box-shadow: 0 0 3px rgba(0,0,0,0.1);
    transform: rotate( -3deg ); /*テープ角度*/
}
.marugo p {
    font-family: "ヒラギノ丸ゴ Pro"
}

a.item-link:hover {
    opacity: 0.7;
    transition-duration: 0.3s;
}

h2 {
    position: relative;
    padding: 1rem 2rem calc(1rem + 10px);
    background: #324256;
}

h2:before {
position: absolute;
top: -0.5rem;
left: -0.5rem;
width: 100%;
height: 100%;
content: '';
border: 4px solid #1e2733;
}

.box {
    color: #324256;
    background: #e2e8f0;
    box-shadow: 0px 0px 0px 10px #e2e8f0;
    border: dashed 0.2rem #324256;
}


.btn,
a.btn,
button.btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    text-decoration: none;
    color: #212529;
    border-radius: 0.5rem;
}

a.btn-flat {
    overflow: hidden;

    color: #fff;
    border-radius: 0;
    background: #000;
}

a.btn-flat span {
    position: relative;
}

a.btn-flat:before {
    position: absolute;
    top: 0;
    left: 0;

    width: 150%;
    height: 500%;

    content: "";
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
    transform: translateX(-98%) translateY(-25%) rotate(45deg);

    background: #03a6ff;
}

a.btn-flat:hover:before {
  -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  transform: translateX(-9%) translateY(-25%) rotate(45deg);
}